import Mock from "../mock";

const database = {
  information: {
    name: 'Sandeep krishna Donepudi',
    aboutContent: "I am a Software Developer and aspiring Data Scientist. I’m always looking to work with new people on exciting projects in the field of Data Science and Computer Vision.",
    aboutContent1: "A passionate Maker and coder who loves to turn data into insights.",
    aboutContent2: " A Computer Science & Engineering Student from Rajiv Gandhi University of Knowledge Technologies,Nuzvid. My research lies in areas like Machine Learning, Data Science and Computer Vision.",
    age: 21,
    phone: '+91 9640354343',
    nationality: 'Indian',
    language: 'English, Telugu, Hindi',
    email: 'sandeepiiitn@gmail.com',
    address: 'Rajahmundry,  Andhra Pradesh, India',
    freelanceStatus: '',
    socialLinks: {
      mail: 'mailto:sandeepiiitn@gmail.com',
      linkedin: 'https://www.linkedin.com/in/sandeep-krishna/',
      github: 'https://github.com/sandeep-krishna',
      kaggle: 'https://www.kaggle.com/sandeepkrish',
      facebook: '',
      twitter: 'https://twitter.com/sandeep_krish',
      pinterest: '',
      behance: '',
      dribbble: '',
      stackoverflow: 'https://stackoverflow.com/users/7161195/sandeep-krishna'
    },
    brandImage: '/images/brand-image.jpg',
    aboutImage: '/images/about-image.jpg',
    aboutImageLg: '/images/about-image-lg.jpg',
    cvfile: '/files/Sandeep-Resume.pdf'
  },
  services: [
    {
      title: "Data Science",
      icon: 'database',
      details: "Worked on several Real-time Data Science Projects using Deep learning frameworks like Tensorflow, PyTorch etc..,"
    },
    {
      title: "AI & Computer Vision ",
      icon: 'display-alt',
      details:  "Looking for opportunities to work on exciting projects in the field of Artificial Intelligence and Computer Vision."

    },
    {
      title: "Web Development",
      icon: 'code',
      details: "Developed several web applications while studying in the university, utilizing both front and back end technologies."
    }
  ],
  reviews: [
    /*{
      id: 1,
      content: "Patrick was focused on success throughout the course, coming early to each class and dedicating time and energy into the homework and group projects.  Clearly, he loves to code and will tenaciously attack any challenge.",
      author: {
        name: 'Mark Carlson',
        designation: 'Lead Developer, Takeoff Technologies'
      },
    },
     {
       id: 2,
       content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Expedita impedit nobis tempore quaerat quibusdam.",
       author: {
         name: 'Susan Yost',
         designation: 'Client'
       }
     },
     {
       id: 3,
       content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit.",
       author: {
         name: 'Irving Feeney',
         designation: 'Fiverr Client'
       }
     }*/
  ],
  skills: [
    {
      title: "Python / Flask / Django",
      value: 85
    },
    {
      title: "Tensorflow / PyTorch / Keras",
      value: 65
    },
    {
      title: "Flutter / PHP",
      value: 80
    },
    {
      title: "HTML / CSS / JavaScript / Bootstrap",
      value: 85
    },
    {
      title: "MySQL / MongoDB",
      value: 75
    },
    {
      title: "C / C++ / Java ",
      value: 65
    },

    
  ],
  portfolios: [
    {
      id: 1,
      title: "Movie Recommendation system",
      subtitle: "Machine Learning",
      imageUrl: "/images/portfolio-image-1.png",
      // largeImageUrl: ["/images/portfolio-image-1-lg.jpg"],
      url: 'https://github.com/sandeep-krishna/movie-recommendation-system'
    },
    {
      id: 2,
      title: "PyScrapy - Web Scraping ",
      subtitle: "Python",
      imageUrl: "/images/portfolio-image-2.jpg",
      // largeImageUrl: ["/images/portfolio-image-2-lg.jpg"],
      url: 'https://github.com/sandeep-krishna/PyScrapy--Web-Scraping-using-python'
    },
    {
      id: 3,
      title: "Speech Emotion Recognition",
      subtitle: "Convolutional Neural Networks",
      imageUrl: "/images/portfolio-image-3.jpg",
       largeImageUrl: ["/images/portfolio-image-3-lg.jpg"],
      url: ''
    },
    {
      id: 4,
      title: "Teckzite'19 Website",
      subtitle: "Ajax, Javascript, PHP",
      imageUrl: "/images/portfolio-image-4.jpg",
      // largeImageUrl: [
      //   "/images/portfolio-image-4-lg.jpg",
      //   "/images/portfolio-image-4-lg2.jpg"
      // ],
      url: 'http://teckzite.rf.gd'
    },
    {
      id: 5,
      title: "SDCAC RGUKTN Website",
      subtitle: "Ajax, Javascript, PHP",
      imageUrl: "/images/portfolio-image-5.png",
      // largeImageUrl: ["/images/portfolio-image-5-lg.jpg"],
      url: 'https://intranet.rguktn.ac.in/sdcac/'
    },
    {
      id: 6,
      title: "Kinect Natural User Interface",
      subtitle: "Microsoft Kinect Sensor",
      imageUrl: "/images/portfolio-image-6.jpg",
      largeImageUrl: ["/images/portfolio-image-6-lg.jpg"],
      url: ''
    }
  ],
  experience: {
    workingExperience: [
      {
        id: 1,
        year: "May 2019 - Present",
        position: "Software Developer Intern",
        company: "Eigenvectors, Pune",
        details:"Developed and worked on many projects in fields like Data Science, Blockchain, Full-stack and Mobile application development."
      },
      // {
      //   id: 2,
      //   year: "2016 - 2018",
      //   position: "Frontend Web Developer",
      //   company: "CBA Company",
      //   details: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas, magni mollitia, aspernatur consequatur accusamus vero eum facere exercitationem velit suscipit ipsam placeat libero. Deleniti exercitationem nostrum quasi. Molestiae, vel porro."
      // },
      // {
      //   id: 3,
      //   year: "2014 - 1016",
      //   position: "UI/UX Designer",
      //   company: "Example Company",
      //   details: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas, magni mollitia, aspernatur consequatur accusamus vero eum facere exercitationem velit suscipit ipsam placeat libero. Deleniti exercitationem nostrum quasi. Molestiae, vel porro."
      // }
    ],
    educationExperience: [
      {
        id: 1,
        year: "June 2017 - Present",
        graduation: "Bachelor of Technology (B.Tech)",
        university: "Rajiv Gandhi University of Knowledge Technologies, Nuzvid",
        details: "Studying Bachelor of Technology (B.Tech) in the field of Computer Science & Engineering (CSE)."
      },
      {
        
        id: 2,
        year: "June 2015 - May 2017",
        graduation: "Pre-University Course (PUC)",
        university: "Rajiv Gandhi University of Knowledge Technologies, Nuzvid",
        details: "Studied Pre-university course (PUC) part of a six-year integrated B.Tech course that offers specialization in tracks such as Mathematics, Physics, Chemistry (equivalent to MPC)."
      
        },
      {
         id: 3,
         year: "June 2014 - April 2015",
         graduation: "X Class (SSC)",
         university: "Z.P.P.High Schoool, Machara",
         details: "Secured CGPA 9.7 out of 10 in SSC"
       }
    ]
  },
  blogs: [
    {
      id: 1,
      title: "React new version is coming!",
      imageUrl: "/images/blog-image-1.jpg",
      content: "ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour.",
      createTime: "03 December 2019"
    },
    {
      id: 2,
      title: "More about react hooks",
      content: "ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour.",
      createTime: "04 December 2019"
    },
    {
      id: 3,
      title: "Next generation javascript learning source",
      content: "ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour.",
      createTime: "05 December 2019"
    },
    {
      id: 4,
      title: "Free tutorial downloading link.",
      imageUrl: "/images/blog-image-2.jpg",
      content: "ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour.",
      createTime: "06 December 2019"
    },
    {
      id: 5,
      title: "Get 2500 free mockup for design.",
      youtubeUrl: "TKnufs85hXk",
      content: "ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour.",
      createTime: "08 December 2019"
    },
    {
      id: 6,
      title: "React vs Vue vs Angular, what is best?",
      vimeoUrl: "23534361",
      content: "ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour.",
      createTime: "10 December 2019"
    },
    {
      id: 7,
      title: "Web design typography & spacing.",
      content: "ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour.",
      createTime: "03 December 2019"
    },
    {
      id: 8,
      title: "React new version is coming with a great features.",
      content: "ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour.",
      createTime: "03 December 2019"
    }
  ],
  contactInfo: {
    phoneNumbers: ['+91 9640354343','+91 9515465266'],
    emailAddress: ['sandeepiiitn@gmail.com','sandeepkrishnadonepudi@gmail.com'],
    address: "Rajahmundry, Andhra Pradesh, India"
  }
}


Mock.onGet("/api/information").reply(config => {
  const response = database.information;
  return [200, response];
});

Mock.onGet("/api/services").reply(config => {
  const response = database.services;
  return [200, response];
});

Mock.onGet("/api/reviews").reply(config => {
  const response = database.reviews;
  return [200, response];
});

Mock.onGet("/api/skills").reply(config => {
  const response = database.skills;
  return [200, response];
});

Mock.onGet("/api/portfolios").reply(config => {
  const response = database.portfolios;
  return [200, response];
});

Mock.onGet("/api/experience").reply(config => {
  const response = database.experience;
  return [200, response];
});

Mock.onGet("/api/contactinfo").reply(config => {
  const response = database.contactInfo;
  return [200, response];
});
