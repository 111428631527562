import React, {useState, useEffect} from "react";
import axios from 'axios';
import LineIcon from 'react-lineicons';
import { Icon } from '@iconify/react';
import kaggleIcon from '@iconify/icons-fa-brands/kaggle';
import ReactGA from 'react-ga';


function Socialicons(props){
  const [socialLinks, setSocialLinks] = useState({});

  useEffect(() => {
    axios.get('/api/information')
      .then(response =>{
        setSocialLinks(response.data.socialLinks);
      })
  }, [])

  
    return (
    <ul className={props.bordered ? 'mi-socialicons mi-socialicons-bordered' : 'mi-socialicons'}>
      {!socialLinks.mail ? null : <li>
        <a onClick={() => ReactGA.event({category: 'User',action: 'Opened Mail'})} rel="noopener noreferrer" target="_blank" href={socialLinks.mail}>
          <LineIcon name="envelope"/>
        </a>
      </li>}
      {!socialLinks.linkedin ? null : <li>
        <a onClick={() => ReactGA.event({category: 'User',action: 'Opened LinkedIn'})} rel="noopener noreferrer" target="_blank" href={socialLinks.linkedin}>
          <LineIcon name="linkedin"/>
        </a>
      </li>}
      {!socialLinks.github ? null : <li>
        <a onClick={() => ReactGA.event({category: 'User',action: 'Opened Github'})} rel="noopener noreferrer" target="_blank" href={socialLinks.github}>
          <LineIcon name="github"/>
        </a>
      </li>}
      {!socialLinks.kaggle ? null : <li>
        <a onClick={() => ReactGA.event({category: 'User',action: 'Opened Kaggle'})} rel="noopener noreferrer" target="_blank" href={socialLinks.kaggle}>
          <Icon icon={kaggleIcon} />
        </a>
      </li>}
      {!socialLinks.facebook ? null : <li>
        <a onClick={() => ReactGA.event({category: 'User',action: 'Opened Facebook'})} rel="noopener noreferrer" target="_blank" href={socialLinks.facebook}>
          <LineIcon name="facebook"/>
        </a>
        
      </li>}
      {!socialLinks.twitter ? null : <li>
        <a onClick={() => ReactGA.event({category: 'User',action: 'Opened Twitter'})} rel="noopener noreferrer" target="_blank" href={socialLinks.twitter}>
          <LineIcon name="twitter"/>
        </a>
      </li>}
      {!socialLinks.pinterest ? null : <li>
        <a onClick={() => ReactGA.event({category: 'User',action: 'Opened Pinterest'})} rel="noopener noreferrer" target="_blank" href={socialLinks.pinterest}>
          <LineIcon name="pinterest"/>
        </a>
      </li>}
      {!socialLinks.behance ? null : <li>
        <a onClick={() => ReactGA.event({category: 'User',action: 'Opened Behance'})} rel="noopener noreferrer" target="_blank" href={socialLinks.behance}>
          <LineIcon name="behance"/>
        </a>
      </li>}
      {!socialLinks.dribbble ? null : <li>
        
        <a onClick={() => ReactGA.event({category: 'User',action: 'Opened Dribbble'})} rel="noopener noreferrer" target="_blank" href={socialLinks.dribbble}>
          <LineIcon name="dribbble"/>
        </a>
      </li>}
      {!socialLinks.stackoverflow ? null : <li>
        <a onClick={() => ReactGA.event({category: 'User',action: 'Opened Stackoverflow'})} rel="noopener noreferrer" target="_blank" href={socialLinks.stackoverflow}>
          <LineIcon name="stackoverflow"/>
        </a>
      </li>}
    </ul>
  );
}

export default Socialicons;
